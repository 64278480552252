exports.components = {
  "component---2706959753-art-investment-for-enterprise-and-professional-use-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-10-11-unlocking-massive-storage-and-performance:-the-st8000ntz01-hard-drive-a-smart-investment-for-enterprise-and-professional-use/index.mdx" /* webpackChunkName: "component---2706959753-art-investment-for-enterprise-and-professional-use-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-0-f-31284-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/0f31284.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-0-f-31284-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-01-25-how-to-decide-which-hard-drive-is-right-for-me-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-01-25-how-to-decide-which-hard-drive-is-right-for-me/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-01-25-how-to-decide-which-hard-drive-is-right-for-me-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-02-we-have-launched-price-comparisons-in-australia-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-02-02-we-have-launched-price-comparisons-in-australia/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-02-we-have-launched-price-comparisons-in-australia-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-03-unveiling-the-breakthrough-cutting-edge-hard-drive-vendor-sets-a-new-record-with-unbeatable-prices-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-02-03-unveiling-the-breakthrough-cutting-edge-hard-drive-vendor-sets-a-new-record-with-unbeatable-prices/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-03-unveiling-the-breakthrough-cutting-edge-hard-drive-vendor-sets-a-new-record-with-unbeatable-prices-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-03-weve-gone-and-launched-some-price-comparisons-in-the-united-kingdom-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-02-03-weve-gone-and-launched-some-price-comparisons-in-the-united-kingdom/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-03-weve-gone-and-launched-some-price-comparisons-in-the-united-kingdom-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-04-launch-of-price-comparison-services-in-germany-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-02-04-launch-of-price-comparison-services-in-germany/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-04-launch-of-price-comparison-services-in-germany-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-05-check-out-our-new-hard-drive-price-comparisons-in-canada-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-02-05-check-out-our-new-hard-drive-price-comparisons-in-canada/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-05-check-out-our-new-hard-drive-price-comparisons-in-canada-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-09-presentamos-nuestro-nuevo-servicio-de-comparacion-de-precios-para-espana-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-02-09-presentamos-nuestro-nuevo-servicio-de-comparación-de-precios-para-españa/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-09-presentamos-nuestro-nuevo-servicio-de-comparacion-de-precios-para-espana-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-10-decouvrez-nos-nouveaux-comparateurs-de-prix-en-france-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-02-10-découvrez-nos-nouveaux-comparateurs-de-prix-en-france/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-10-decouvrez-nos-nouveaux-comparateurs-de-prix-en-france-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-16-whats-the-difference-between-m-2-ssds-sata-and-nvme-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-02-16-whats-the-difference-between-m2-ssds-sata-and-nvme/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-02-16-whats-the-difference-between-m-2-ssds-sata-and-nvme-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-03-11-new-removable-product-types-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-03-11-new-removable-product-types/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-03-11-new-removable-product-types-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-03-12-new-optical-product-types-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-03-12-new-optical-product-types/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-03-12-new-optical-product-types-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-03-13-the-future-of-compare-hard-drives-innovations-on-the-horizon-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-03-13-the-future-of-compare-hard-drives-innovations-on-the-horizon/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-03-13-the-future-of-compare-hard-drives-innovations-on-the-horizon-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-03-21-we-just-added-price-history-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-03-21-we-just-added-price-history/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-03-21-we-just-added-price-history-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-04-04-leveraging-hot-price-alerts-seizing-opportunities-at-their-lowest-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-04-04-leveraging-hot-price-alerts-seizing-opportunities-at-their-lowest/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-04-04-leveraging-hot-price-alerts-seizing-opportunities-at-their-lowest-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-04-05-unleash-blazing-speeds-with-crucial-p-3-1-tb-pcie-m-2-2280-ssd-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-04-05-unleash-blazing-speeds-with-crucial-p3-1tb-pcie-m2-2280-ssd/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-04-05-unleash-blazing-speeds-with-crucial-p-3-1-tb-pcie-m-2-2280-ssd-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-04-15-a-look-into-seagates-firecuda-performance-drives-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-04-15-a-look-into-seagates-firecuda-performance-drives/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-04-15-a-look-into-seagates-firecuda-performance-drives-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-05-06-the-ct-4000-x-9-prossd-902-revolutionizing-storage-solutions-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-05-06-the-ct4000x9prossd902-revolutionizing-storage-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-05-06-the-ct-4000-x-9-prossd-902-revolutionizing-storage-solutions-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-05-06-unleash-the-speed-fikwot-fn-970-m-2-2280-ssd-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-05-06-unleash-the-speed-fikwot-fn970-m2-2280-ssd/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-05-06-unleash-the-speed-fikwot-fn-970-m-2-2280-ssd-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-05-16-australia-hard-drive-prices-are-dropping-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-05-16-australia-hard-drive-prices-are-dropping/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-05-16-australia-hard-drive-prices-are-dropping-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-05-18-comparing-the-crucial-x-9-pro-1-tb-portable-ssd-a-smart-investment-for-your-storage-needs-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-05-18-comparing-the-crucial-x9-pro-1tb-portable-ssd-a-smart-investment-for-your-storage-needs/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-05-18-comparing-the-crucial-x-9-pro-1-tb-portable-ssd-a-smart-investment-for-your-storage-needs-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-07-15-experience-lightning-fast-performance-with-the-ediloca-en-870-m-2-ssd-pcie-40-x-4-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-07-15-experience-lightning-fast-performance-with-the-ediloca-en870-m2-ssd-pcie-40x4/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-07-15-experience-lightning-fast-performance-with-the-ediloca-en-870-m-2-ssd-pcie-40-x-4-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-10-11-fikwot-fx-991-the-durable-storage-solution-you-can-rely-on-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-10-11-fikwot-fx991:-the-durable-storage-solution-you-can-rely-on/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-10-11-fikwot-fx-991-the-durable-storage-solution-you-can-rely-on-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-10-12-st-10000-nm-0046-high-capacity-storage-with-unmatched-reliability-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/2024-10-12-st10000nm0046-high-capacity-storage-with-unmatched-reliability'/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-2024-10-12-st-10000-nm-0046-high-capacity-storage-with-unmatched-reliability-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-300-tb-hard-drive-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/300tb-hard-drive.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-300-tb-hard-drive-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-4-gb-pack-of-10-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/4gb-pack-of-10.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-4-gb-pack-of-10-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-500-gb-memory-stick-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/500gb-memory-stick.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-500-gb-memory-stick-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-652611-b-21-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/652611-b21.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-652611-b-21-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-benefits-of-external-storage-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/benefits-of-external-storage.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-benefits-of-external-storage-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-buffalo-external-ssd-1-tb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/buffalo-external-ssd-1tb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-buffalo-external-ssd-1-tb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-camcorder-discs-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/camcorder-discs.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-camcorder-discs-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-corsair-flash-survivor-stealth-512-gb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/corsair-flash-survivor-stealth-512gb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-corsair-flash-survivor-stealth-512-gb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-crucial-new-2024-t-705-2-tb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/crucial-new-2024-t705-2tb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-crucial-new-2024-t-705-2-tb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ct-4000-p-3-pssd-801-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/ct4000p3pssd801.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ct-4000-p-3-pssd-801-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-dogfish-ssd-msata-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/dogfish-ssd-msata.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-dogfish-ssd-msata-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-e-27-t-storage-solutions-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/e27t-storage-solutions.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-e-27-t-storage-solutions-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ediloca-en-870-4-tb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/ediloca-en870-4tb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ediloca-en-870-4-tb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fanxiang-512-g-ff-952-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/fanxiang-512g-ff952.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fanxiang-512-g-ff-952-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fanxiang-s-770-review-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/fanxiang-s770-review.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fanxiang-s-770-review-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fikwot-fn-970-review-extended-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/fikwot-fn970-review-extended.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fikwot-fn-970-review-extended-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fikwot-fn-970-review-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/fikwot-fn970-review.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fikwot-fn-970-review-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fips-encrypted-hard-drive-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/fips-encrypted-hard-drive.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-fips-encrypted-hard-drive-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-hbrpeknx-0202-al-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/hbrpeknx0202al.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-hbrpeknx-0202-al-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-hds-724040-ale-640-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/hds724040ale640.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-hds-724040-ale-640-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-inland-tn-450-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/inland-tn450.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-inland-tn-450-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-kingston-2-tb-ssd-external-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/kingston-2tb-ssd-external.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-kingston-2-tb-ssd-external-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-klevv-cras-c-710-guide-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/klevv-cras-c710-guide.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-klevv-cras-c-710-guide-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-leven-jps-800-2-tb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/leven-jps800-2tb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-leven-jps-800-2-tb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-maxone-hard-drive-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/maxone-hard-drive.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-maxone-hard-drive-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-md-16000-gsa-25672-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/md16000gsa25672.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-md-16000-gsa-25672-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-mdd-14-tsata-25672-e-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/mdd14tsata25672e.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-mdd-14-tsata-25672-e-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-pendrive-de-1-gb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/pendrive-de-1gb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-pendrive-de-1-gb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-samdata-external-hard-drives-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/samdata-external-hard-drives.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-samdata-external-hard-drives-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-samdata-internal-hard-drives-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/samdata-internal-hard-drives.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-samdata-internal-hard-drives-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-sdcz-430-512-g-gam-46-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/sdcz430-512g-gam46.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-sdcz-430-512-g-gam-46-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-seagate-exos-x-20-16-tb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/seagate-exos-x20-16tb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-seagate-exos-x-20-16-tb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-seagate-expansion-desktop-14-tb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/seagate-expansion-desktop-14tb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-seagate-expansion-desktop-14-tb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-seagate-expansion-desktop-14-tb-review-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/seagate-expansion-desktop-14tb-review.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-seagate-expansion-desktop-14-tb-review-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-sn-730-1-tb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/sn730-1tb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-sn-730-1-tb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-10000-nm-0206-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st10000nm0206.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-10000-nm-0206-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-16000-ve-000-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st16000ve000.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-16000-ve-000-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-3250310-ns-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st3250310ns.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-3250310-ns-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-4000-ne-001-cmr-vs-smr-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st4000ne001-cmr-vs-smr.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-4000-ne-001-cmr-vs-smr-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-4000-nm-0125-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st4000nm0125.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-4000-nm-0125-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-500-vm-000-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st500vm000.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-500-vm-000-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-6000-nm-000-b-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st6000nm000b.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-6000-nm-000-b-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-8000-as-0003-external-hard-drive-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st8000as0003-external-hard-drive.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-8000-as-0003-external-hard-drive-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-8000-as-0003-internal-hard-drive-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/st8000as0003-internal-hard-drive.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-st-8000-as-0003-internal-hard-drive-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-tarjeta-micro-sd-alta-velocidad-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/tarjeta-micro-sd-alta-velocidad.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-tarjeta-micro-sd-alta-velocidad-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-teamgroup-mp-44-s-storage-solutions-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/teamgroup-mp44s-storage-solutions.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-teamgroup-mp-44-s-storage-solutions-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-teamgroup-qx-2-tb-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/teamgroup-qx-2tb.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-teamgroup-qx-2-tb-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-toshiba-md-04-aca-600-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/toshiba-md04aca600.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-toshiba-md-04-aca-600-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ts-512-gesd-330-c-external-storage-solution-for-modern-users-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/ts512gesd330c-external-storage-solution-for-modern-users.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ts-512-gesd-330-c-external-storage-solution-for-modern-users-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ts-512-gesd-330-c-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/ts512gesd330c.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ts-512-gesd-330-c-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ts-512-gesd-330-c-ultimate-guide-to-compact-flash-and-usb-storage-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/ts512gesd330c-ultimate-guide-to-compact-flash-and-usb-storage.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-ts-512-gesd-330-c-ultimate-guide-to-compact-flash-and-usb-storage-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-understanding-om-3-pdp-3512-b-a-01-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/understanding-om3pdp3512b-a01.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-understanding-om-3-pdp-3512-b-a-01-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wd-141-kfgx-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/wd141kfgx.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wd-141-kfgx-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wd-30-efrx-68-euzn-0-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/wd30efrx-68euzn0.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wd-30-efrx-68-euzn-0-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wd-5000-aacs-comparison-with-external-storage-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/wd5000aacs-comparison-with-external-storage.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wd-5000-aacs-comparison-with-external-storage-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wd-5000-aacs-internal-hard-drive-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/wd5000aacs-internal-hard-drive.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wd-5000-aacs-internal-hard-drive-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wdbfbe-0160-jbk-nesn-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/wdbfbe0160jbk-nesn.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wdbfbe-0160-jbk-nesn-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wdbwlg-0100-hbk-nesn-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/wdbwlg0100hbk-nesn.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wdbwlg-0100-hbk-nesn-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wds-100-t-1-x-0-e-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/compare-hard-drives-website/compare-hard-drives-website/packages/website/src/content/blog-posts/wds100t1x0e.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-wds-100-t-1-x-0-e-mdx" */),
  "component---src-templates-comparison-tool-tsx": () => import("./../../../src/templates/comparison-tool.tsx" /* webpackChunkName: "component---src-templates-comparison-tool-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

